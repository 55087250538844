import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/ruoyi'

// 查询用户列表
export function listUser(query) {
  return request({
    url: '/system/user/list',
    method: 'get',
    params: query
  })
}

// 查询用户列表
export function listWechatUser(query) {
  return request({
    url: '/system/user/listWechat',
    method: 'get',
    params: query
  })
}
// 查询用户列表
export function listAllUser(query) {
  return request({
    url: '/system/user/listAll',
    method: 'get',
    params: query
  })
}
// 搜索用户列表
export function queryUser(query) {
  return request({
    url: '/system/user/queryUser',
    method: 'get',
    params: query
  })
}

// 根据部门id查询用户
export function findList(deptId) {
  return request({
    url: '/system/user/findList/' + (deptId || ''),
    method: 'get'
  })
}

// 查询用户详细
export function getUser(userId) {
  return request({
    url: '/system/user/' + praseStrEmpty(userId),
    method: 'get'
  })
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/system/user',
    method: 'post',
    data: data
  })
}

// 修改用户
export function updateUser(data) {
  return request({
    url: '/system/user',
    method: 'put',
    data: data
  })
}

// 删除用户
export function delUser(userId) {
  return request({
    url: '/system/user/' + userId,
    method: 'delete'
  })
}

// 导出用户
export function exportUser(query) {
  return request({
    url: '/system/user/export',
    method: 'get',
    params: query
  })
}

// 用户密码重置
export function resetUserPwd(userId, password) {
  const data = {
    userId,
    password
  }
  return request({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 用户状态修改
export function changeUserStatus(userId, status) {
  const data = {
    userId,
    status
  }
  return request({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  })
}

// 查询用户个人信息
export function getUserProfile() {
  return request({
    url: '/system/user/profile',
    method: 'get'
  })
}

// 修改用户个人信息
export function updateUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data: data
  })
}

// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
  const data = {
    oldPassword,
    newPassword
  }
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  })
}

// 用户头像上传
export function uploadAvatar(data) {
  return request({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  })
}

// 下载用户导入模板
export function importTemplate() {
  return request({
    url: '/system/user/importTemplate',
    method: 'get'
  })
}

// 导入用户
export function importData(data) {
  return request({
    url: '/system/user/importData',
    method: 'post',
    data: data
  })
}

// 设置用户使用期限
export function setExpiration(userId, expirationDate) {
  const data = {
    userId,
    expirationDate
  }
  return request({
    url: '/system/user/setExpiration',
    method: 'put',
    data: data
  })
}

// 用户数据权限
export function dataScope(data) {
  return request({
    url: '/system/user/dataScope',
    method: 'put',
    data: data
  })
}

// 微信解绑
export function userWxUnbind(id) {
  return request({
    url: '/system/user/unbind/' + id,
    method: 'get'
  })
}
